<template>
    <div class="index-entry__block">
        <div class="index-entry__block__inner">
            <div class="index-entry__block__inner__form">
                <h3 class="index-entry__block__inner__form__ttl">ご登録はこちら</h3>
                <p class="index-entry__block__inner__form__txt">専門のキャリアアドバイザーが<br>あなたに最適な転職活動をしっかりサポートします。</p>
                <div class="index-entry__block__inner__form__block">
                    <form id="form-entry" action="#" method="post">
                        <input type="hidden" v-model="place" name="place">
                        <input type="hidden" v-model="status" name="state">
                        <input type="hidden" v-model="exp" name="exp">
                        <input type="hidden" v-model="name" name="name">
                        <input type="hidden" v-model="age" name="age">
                        <input type="hidden" v-model="tel" name="tel">
                        <input type="hidden" v-model="email" name="email">
                        <transition :name="transition_name">
                            <div v-if="state == 1" key="step1" class="index-entry__block__inner__form__block__inner">
                                <table class="table index-entry__block__inner__form__block__inner__table">
                                    <tbody>
                                    <tr>
                                        <th>お住まい<span class="label-must">必須</span></th>
                                        <td>
                                            <select class="pref" v-model="place" required>
                                                <option value="" disabled selected>都道府県</option>
                                                <option value="東京23区">東京23区</option>
                                                <option value="東京都内">東京都内</option>
                                                <option value="神奈川県">神奈川県</option>
                                                <option value="千葉県">千葉県</option>
                                                <option value="埼玉県">埼玉県</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>就業状況</th>
                                        <td>
                                            <label :class="{ 'radio': true, 'check': status == '在職中' }"><input type="radio" value="在職中" v-model="status"><span>在職中</span></label>
                                            <label :class="{ 'radio': true, 'check': status == '離職中' }"><input type="radio" value="離職中" v-model="status"><span>離職中</span></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>業界経験</th>
                                        <td>
                                            <select class="keiken" v-model="exp">
                                                <option value="" disabled selected>業界経験を選択してください</option>
                                                <option value="あり">あり</option>
                                                <option value="なし">なし</option>
                                                <option value="１年">１年</option>
                                                <option value="２年">２年</option>
                                                <option value="３年">３年</option>
                                                <option value="４年">４年</option>
                                                <option value="５年">５年</option>
                                                <option value="６年">６年</option>
                                                <option value="７年">７年</option>
                                                <option value="８年">８年</option>
                                                <option value="９年">９年</option>
                                                <option value="１０年以上">１０年以上</option>
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="index-entry__block__inner__form__block__inner__btn">
                                    <button :class="{'btn': true, 'btn--red': !has_place, 'btn--gray02': has_place}" @click="next" :disabled="has_place">次へ<i class="icon-angle-right icon-fix-right"></i></button>
                                </div>
                            </div>

                            <div v-if="state == 2" key="step2" class="index-entry__block__inner__form__block__inner">
                                <table class="table index-entry__block__inner__form__block__inner__table">
                                    <tbody>
                                    <tr>
                                        <th>氏名<span class="label-must">必須</span></th>
                                        <td>
                                            <input type="text" class="text" placeholder="山田太郎" v-model="name" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>年齢<span class="label-must">必須</span></th>
                                        <td>
                                            <input type="text" class="text" placeholder="25" v-model="age" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>電話番号<span class="label-must">必須</span></th>
                                        <td>
                                            <input type="text" class="text" placeholder="0234567890" v-model="tel" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>メール<br class="pc-view">アドレス<span class="label-must">必須</span></th>
                                        <td>
                                            <input type="email" class="text" placeholder="info@ramen-tenshoku.com" v-model="email" required>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="index-entry__block__inner__form__block__inner__btn index-entry__block__inner__form__block__inner--confirm">
                                    <button type="button" class="btn btn--gray" @click="back"><i class="icon-fix-left icon-angle-left"></i>修正する</button>
                                    <button type="button" class="btn btn--red" @click="submit">無料登録する<i class="icon-angle-right icon-fix-right"></i></button>
                                    <input id="input_submit" type="submit" v-show="false">
                                </div>
                            </div>
                        </transition>
                    </form>
                </div>
            </div>
            <ul class="index-entry__block__inner__arrow">
                <li class="index-entry__block__inner__arrow__item"><span :class="{'index-entry__block__inner__arrow__item__link': true,'index-entry__block__inner__arrow__item__link--hover': state == 1}"></span></li>
                <li class="index-entry__block__inner__arrow__item"><span :class="{'index-entry__block__inner__arrow__item__link': true,'index-entry__block__inner__arrow__item__link--hover': state == 2}"></span></li>
                <li class="index-entry__block__inner__arrow__item"><span class="index-entry__block__inner__arrow__item__link"></span></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                place: null,
                status: null,
                exp: null,
                name: null,
                age: null,
                tel: null,
                email: null,
                transition_name: 'show-next',
                state: 1,
            }
        },
        computed: {
            has_place() {
                return !!!this.place;
            }
        },
        methods: {
            next(event){
                event.preventDefault();
                // if(!$('#form-entry')[0].reportValidity()) return false;
                this.transition_name = 'show-next';
                this.state++;
            },
            back(){
                this.transition_name = 'show-prev';
                this.state--;
            },
            submit(event) {
                event.preventDefault();
                // if(!$('#form-entry')[0].reportValidity()) return false;
                $('#input_submit').click();
            }
        }

    }
</script>

<style scoped>
    .show-next-enter-active, .show-next-leave-active,
    .show-prev-enter-active, .show-prev-leave-active  {
        transition: all .4s;
    }
    .show-next-enter, .show-prev-leave-to {
        transform: translateX(5%);
        opacity: 0;
    }
    .show-next-leave-to, .show-prev-enter {
        transform: translateX(-5%);
        opacity: 0;
    }
</style>
